export function currencyFormatter(price) {
    return new Intl.NumberFormat('sl-SI', {
        style: 'currency',
        currency: 'EUR',
    }).format(price);
}

export function percentageFormatter(ratio) {
    if (ratio) {
        const percentNubmer = Math.round(ratio * 100);
        return `-${percentNubmer}%`;
    }
}

export default {
    currencyFormatter,
    percentageFormatter,
}