<template>
  <div
    v-click-outside="onClickOutside"
    class="w-search"
    tabindex="0"
  >
    <div class="w-search__input-container">
      <div class="w-search__input-wrapper">
        <input
          type="text"
          class="w-search__input"
          :class="{ 'w-search__input--open': showResultsBlock }"
          :value="searchString"
          placeholder="Poišči svoj stil..."
          @input="evt => searchString = evt.target.value"
          @focus="onSearchInputFocus()"
          @keyup.enter="goToSearchPage()"
        >
        <CloseIcon
          v-if="isSearchResultsDialogOpen"
          class="w-search__input-clear"
          aria-label="Počisti iskalni niz"
          @click="searchString = ''"
        />
        <div
          v-if="isSearchLoading"
          class="w-search__loader lds-ellipsis"
        >
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
      </div>
      <a
        class="w-search__search-icon-link"
        :href="searchResults?.show_more_url"
      >
        <SearchIcon
          class="w-search__search-icon"
          aria-label="Dimnenzije okvirja"
        />
      </a>
    </div>
    <div
      v-if="hasSearchResults && isSearchResultsDialogOpen"
      class="w-search-results w-search-results--open"
    >
      <div class="w-search-results__products-list">
        <a
          v-for="result in searchResults.products"
          :key="result.product.id"
          class="w-search-results__result"
          :href="result.product.permalink"
        >
          <img
            v-if="result.product.images.archiveImages[0]?.thumbnail['1x'][0]"
            class="w-search-result__product-image"
            :src="result.product.images.archiveImages[0].thumbnail['1x'][0]"
            :srcset="variationImageSrcset(result.product.images.archiveImages[0].thumbnail)"
            :alt="`${result.product.name} ${result.product.attributes.pa_barva} ${result.product.attributes.pa_velikost}`"
          >
          <div class="w-search-result-data">
            <div class="w-search-result-data__title">
              {{ result.product.name }}
            </div>
            <div class="w-search-result-data-attributes">
              <div class="w-search-result-data-attributes__attribute w-search-result-data-attributes__attribute--color">
                Barva: <strong class="w-search-result-data-attributes__attribute--value">{{
                  result.product.attributes.pa_barva
                }}</strong>
              </div>
              <div
                class="w-search-result-data-attributes__attribute w-search-result-datadata-attributes__attribute--size"
              >
                Velikost: <strong class="w-search-result-data-attributes__attribute--value">{{
                  result.product.attributes.pa_velikost
                }}</strong>
              </div>
            </div>
            <div class="w-search-result-data__prices">
              <div
                v-if="result.product.prices.salePrice && !result.product?.prices?.salePriceAsRegular"
                class="w-search-result-data__price--sale"
              >
                {{ formatPrices(result.product.prices.salePrice) }}
              </div>
              <div
                class="w-search-result-data__price--regular"
                :class="{
                  'w-search-result-data__price--line-through' : result.product.prices.salePrice && !result.product.prices.salePriceAsRegular,
                  'w-search-result-data__price--regular-only' : !result.product.prices.salePrice,
                }"
              >
                {{ formatPrices(!result.product.prices?.salePriceAsRegular || !result.product.prices.salePrice ? result.product.prices.regularPrice : result.product.prices.salePrice) }}
              </div>
            </div>
          </div>
        </a>
      </div>
      <div
        v-if="searchResults?.show_more_url"
        class="w-search-results__more"
      >
        <a :href="searchResults?.show_more_url">Prikaži vse rezultate</a>
      </div>
    </div>
    <div
      v-else-if="!hasSearchResults && isSearchResultsDialogOpen"
      class="w-search-results w-search-results--open"
    >
      <div class="w-search-results__result w-search-results__result--no-results">
        Ni rezultatov za <i>{{ searchResults.search_string }}</i>.
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import endpoints from './endpoints';
import _ from 'lodash';
import vClickOutside from 'click-outside-vue3';
import SearchIcon from '~shared/assets/icons/icon-search.svg';
import CloseIcon from '~shared/assets/icons/icon-close.svg';
import numberUtils from '~shared/utils/numberUtils';

export default {
  name: 'SearchWidget',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    SearchIcon,
    CloseIcon,
  },
  data() {
    return {
      searchString: null,
      searchResults: [],
      showResultsBlock: false,
      SearchIcon,
      CloseIcon,
      isSearchLoading: false,
    };
  },
  computed: {
    isSearchResultsDialogOpen() {
      if (this.showResultsBlock && !this.isSearchLoading) {
        document.querySelector('body').classList.add('no-scroll--mobile')
        return true;
      } else {
        document.querySelector('body').classList.remove('no-scroll--mobile')
        return false;
      }
    },
    hasSearchResults() {
      const searchResultProducts = this.searchResults?.products;
      if (searchResultProducts) {
        return searchResultProducts.length > 0;
      } else return null;
    },
  },
  watch: {
    searchString(value) {
      if (!this.searchString) {
        this.showResultsBlock = false;
        this.searchResults = null;
        return;
      }
      this.updateValue(value);
    },
  },
  mounted() {
    // this.fetchSearchResults('aviator');
  },
  methods: {
    fetchSearchResults(value) {
      this.isSearchLoading = true;
      axios.get(
        `${endpoints.OP_API_SEARCH}`,
        {
          params: {
            search: value,
          },
        },
      ).then((result) => {
        this.searchResults = result?.data;
        this.isSearchLoading = false;
        this.showResultsBlock = true;
      });
    },
    updateValue: _.debounce(function (value) {
      this.fetchSearchResults(value);
    }, 500),
    onClickOutside() {
      this.showResultsBlock = false;
    },
    formatPrices(price) {
      return numberUtils.currencyFormatter(price);
    },
    onSearchInputFocus() {
      if (!this.searchString) {
        return;
      }
      this.showResultsBlock = true;
    },
    goToSearchPage() {
      window.location.href = (`${window.location.origin}?s=${this.searchString}`)
    },
    variationImageSrcset(thumbnails) {
      return `${thumbnails['1x'][0]}, ${thumbnails['2x'][0]} 2x, ${thumbnails['3x'][0]} 3x`;
    },
  },
};
</script>

<style scoped>
@import "~shared/assets/variables.pcss";
@import "~shared/assets/responsive.pcss";
@import "~shared/assets/reset.pcss";

.w-search {
  --desktop-search-input-width: 12.5rem;
  --result-hover-color: #ececf1;
  --container-background-color: #ffffff;
  --search-input-bg-color: var(--section-bg-color);
  --search-input-placeholder-color: var(--theme-primary);

  position: relative;

  @media (--md) {
    --desktop-search-input-width: 8.5rem;
  }

  @media (--lg) {
    --desktop-search-input-width: 12.5rem;
    position: relative;
    top: 0;
  }

  &__input-container {
    display: flex;

    @media (--md) {
      background: transparent;
    }
  }

  &__input-wrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
  }

  &__input {
    border: none;
    background: var(--search-input-bg-color);
    padding: 0.375rem 1rem;
    border-radius: 50rem;
    margin-right: 0.25rem;
    width: 100%;
    transition: width 0.1s ease-in-out;
    height: 1.25rem;
    font-weight: 200;
    font-size: 0.75rem;

    &::placeholder {
      color: var(--search-input-placeholder-color);
      font-weight: 300;
    }

    @media (--md) {
      width: var(--desktop-search-input-width)
    }
  }

  &__input-clear {
    position: absolute;
    top: 0.675rem;
    right: 1.25rem;
    width: 0.5rem;
    opacity: 0.5;
  }

  &__search-icon {
    width: 1.25rem;
    color: var(--search-icon-color);
    padding: 0 0.25rem;
  }

  &__search-icon-link {
    display: flex;
  }

  &-results {
    position: absolute;
    left: -0.75rem;
    box-shadow: 0 10px 10px 3px rgb(0 0 0 / 10%);
    top: 2.75rem;
    background: #fff;
    font-size: 0.875rem;
    height: calc(95vh - 108px);
    height: calc(100dvh - 108px);
    overflow: auto;
    z-index: 999;
    width: 100vw;

    @media (--md) {
      left: auto;
      right: 0;
      width: 25rem;
      height: auto;
      top: 2.5rem;
      box-shadow: 0 4px 10px 2px rgb(0 0 0 / 5%);
    }

    &__more {
      text-align: center;
      padding: 1rem 0;
      font-weight: 400;
      position: sticky;
      bottom: 0;
      width: 100%;
      background: white;
    }

    &__result {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 0.5rem;

      &:hover {
        background: var(--result-hover-color);
      }

      &--url {
        font-size: 0.875rem;
      }

      &--no-results {
        justify-content: center;
      }
    }
  }

  &-result {
    &__product-image {
      width: 5.25rem;
      height: auto;
      mix-blend-mode: multiply;
    }

    &-data {
      &__title {
        font-size: var(--text-size-normal);
        line-height: 1.25;
      }

      &-attributes {
        display: flex;
        flex-flow: row wrap;
        gap: 0.5rem;
        font-size: 0.675rem;
        padding: 0.125rem 0;
        font-weight: 200;

        &__attribute {
          &--value {
            text-transform: uppercase;
            font-weight: 500;
          }
        }
      }

      &__prices {
        display: flex;
        align-items: flex-end;
        gap: 0.5rem;
      }

      &__price {
        &--regular-only {
          font-weight: 500;
          font-size: 1rem;
        }

        &--sale {
          font-weight: 500;
          font-size: 1rem;
        }

        &--line-through {
          opacity: 0.5;
          text-decoration: line-through;
          font-size: 0.75rem;
        }
      }
    }
  }
}

.lds-ellipsis {
  display: inline-block;
  position: absolute;
  top: 0;
  right: -0.5rem;
  width: 60px;
  height: 2rem;
}

.lds-ellipsis div {
  position: absolute;
  top: 13px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--search-input-placeholder-color);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 0px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 0px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 12px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 24px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
</style>
