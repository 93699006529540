<template>
  <div class="brands-slider">
    <swiper-container
      ref="brandsSlider"
      class="brands-slider__swiper"
      :breakpoints="{
        0: {
          slidesPerView: 3,
          slidesPerGroup: 1,
        },
        767.98: {
          slidesPerView: 4,
          slidesPerGroup: 2,
        },
        1023.98: {
          slidesPerView: 6,
          slidesPerGroup: 2,
        },
        1679.98: {
          slidesPerView: 8,
          slidesPerGroup: 2,
        },
      }"
      :loop="true"
      :autoplay="{
         delay: 4 * 1000,
         disableOnInteraction: true,
      }"
      :navigation="true"
      :pagination="{
        clickable: true,
      }"
      :grab-cursor="true"
      :loop-add-blank-slides="false"
    >
      <swiper-slide
        v-for="(slide, index) in decodedSlides"
        :key="`brandsSlider-${index}`"
        class="brands-slider__swiper-slide"
      >
        <a
          class="brands-slider__link"
          :href="slide.brandLink"
        >
          <div
            v-if="slide.brandImage"
            class="brands-slider__image"
          >
            <div
              class="brands-slider__image-container"
              :style="{
                backgroundImage: `url(${slide.brandImage.sizes?.thumbnail})`
              }"
            >
            </div>
            <div class="brands-slider__name" v-html="slide.brandName"/>
          </div>
          <div
            v-else-if="slide.brandLogo"
            class="brands-slider__logo-container"
          >
            <img
              :src="slide.brandLogo"
              class="brands-slider__logo"
            >
          </div>
          <div
            v-else
            class="posts-slider__name"
            v-html="slide.brandName"
          />
        </a>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watchEffect} from 'vue';
import {register} from 'swiper/element/bundle';

register();

const props = defineProps({
  slides: {
    type: Array,
    required: true
  }
})

const brandsSlider = ref(null);

watchEffect(() => {
  if (brandsSlider.value) {
    brandsSlider.value.initialize();
  }
})
const decodedSlides = computed(() => {
  const parsedJson = JSON.parse(props.slides)
  return Object.entries(parsedJson).map((e) => (e[1]));
});

onMounted(() => {
  let str = '';
  decodedSlides.value.forEach((slide) => {
    // console.log(slide.brandImage?.ID)
    if (!slide.brandImage?.ID) return
    str += `${slide.brandImage?.ID}, `
  })
})
</script>

<style>
@import "~shared/assets/responsive.pcss";
@import "~shared/assets/reset.pcss";

.brands-slider {
  &__swiper {
    --swiper-pagination-color: var(--dark-gray);
    --swiper-navigation-size: 1.25rem;
    --swiper-pagination-bullet-size: 0.3755rem;
    --swiper-pagination-bullet-horizontal-gap: 0.25rem;
    --swiper-pagination-bullet-size-inactive: 0.375rem;
    --swiper-pagination-bullet-inactive-opacity: 0.1;

    --arrow-color: rgba(255, 255, 255, 0.5);
    --top-position: calc(50% - 1rem);

    padding: 1.75rem 0 1rem;

    @media (--md) {
      --swiper-pagination-bullet-size: 0.75rem;
      --swiper-pagination-bullet-horizontal-gap: 0.375rem;

      padding: 2.75rem 0 1.25rem
    }

    &::part(wrapper) {
      padding-bottom: 2.5rem;

      @media (--md) {
        padding-bottom: 3.25rem;
      }
    }

    &::part(button-next) {
      background: rgba(0, 0, 0, 0.15);
      padding: 0.75rem 0.5rem 0.75rem 0.75rem;
      border-radius: 25rem 0 0 25rem;
      right: 0;
      backdrop-filter: blur(3px);
      color: var(--arrow-color);
      top: var(--top-position);
    }

    &::part(button-prev) {
      background: rgba(0, 0, 0, 0.15);
      padding: 0.75rem 0.75rem 0.75rem 0.5rem;
      border-radius: 0 25rem 25rem 0;
      left: 0;
      backdrop-filter: blur(3px);
      color: var(--arrow-color);
      top: var(--top-position);
    }
  }

  &__link {
    height: 100%;
  }

  &__swiper-slide {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.5rem;

    &:hover {
      .brands-slider__image-container {
        box-shadow: 0 0 16px 0 rgba(51, 51, 51, 0.33);
        transform: scale(1.05);
      }

      .brands-slider__name {
        --text-shadow-color: rgba(51, 51, 51, 0.25);

        text-shadow:
          -0.1px -0.1px 0 var(--text-shadow-color),
          0.1px -0.1px 0 var(--text-shadow-color),
          -0.1px 0.1px 0 var(--text-shadow-color),
          0.1px 0.1px 0 var(--text-shadow-color);
      }
    }
  }

  &__image {
    max-width: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__image-container {
    --logo-image-size: 5.25rem;

    width: var(--logo-image-size);
    height: var(--logo-image-size);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 1rem;
    overflow: hidden;
    transition: 0.2s ease-in-out;

    @media (--md) {
      --logo-image-size: 7.25rem;
    }
  }

  &__name {
    color: var(--theme-primary);
    font-weight: 400;
    font-size: var(--text-size-sm);
    text-align: center;
    line-height: 1.25;
    padding: 1rem 0;
    width: 100%;
    max-width: 100%;
    transition: all 0.2s;
    text-transform: uppercase;
  }

  &__logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    width: 100%;
    max-width: 7.5rem;
    max-height: 2.75rem;
  }
}
</style>
